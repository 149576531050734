import styled, { css } from 'styled-components';

import { Icon } from '../ui';
import { ModalContent } from '../ui/Modal';

import { below } from '../../styles/libs';

export const BookingActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0rem 0.5rem;
  margin-top: 0.5rem;

  ${({ theme }) => below(theme.TABLET)`
    justify-content: center;
    gap: 0rem 1rem;
    margin-top: 1rem;
  `}
`;

export const BookingActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; // per designs
  height: 40px;
  border-radius: 3px;
  cursor: pointer;

  ${({ theme }) => css`
    background: ${theme.COLOR_CYAN_DARK};
  `}
`;

export const ActionIcon = styled(Icon)`
  transform: scale(1.375); // To get 16x16px icons to design-specific 22x22px
`;

export const ActionIconWrapper = styled.div`
  display: inline;
  ${({ bordered, rounded, theme }) => css`
    border-radius: ${rounded ? '50%' : '0'};
    ${bordered && css`
      border: 2px solid ${theme.COLOR_CYAN_DARK};
      padding: 1rem;
    `}
  `}
`;

export const ModalIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
`;

export const ModalIconWrapper = styled.div`
  position: relative;
  width: 40px; // per designs
  height: 40px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 50%;

  ${({ theme }) => `
    border: 2px solid ${theme.COLOR_CYAN_DARK};
  `}
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  margin: 0 auto;
`;

export const ModalTextContent = styled.div`
  margin: 1rem 0;
`;

export const LimitedModalContent = styled(ModalContent)`
  ${({ theme }) => below(theme.TABLET)`
    margin: 1rem;
  `}
`;

export default null;
